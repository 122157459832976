import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from 'gatsby'
import { loginLayoutStyles, LoginIllustration } from '../shared/elements'

const Container = styled.div`
  ${loginLayoutStyles}

  .message {
    width: 480px;
    max-width: 100%;

    .actions {
      .btn-primary {
        padding: 6px 42px;
      }
    }
  }
`

export default function ResetPasswordSent(props) {
  return (
    <Container>
      <LoginIllustration />
      <div className="form-container">
        <div className="message">
          <SVG src="/images/logo-dark.svg" className="head-logo" />
          <h3>Password reset</h3>

          <p>An email has been sent to your address</p>

          <div className="actions mt-4">
            <Link to="/" className="btn btn-primary btn-square">
              Back
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}
